import React, { memo, FC, useCallback, useContext } from "react";
import { useRouter } from "next/router";
import { FeedContext } from "@finbackoffice/site-core";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import { useSelectedTopLeagueTournamentId } from "hooks";
import ActiveLink from "components/base/active-link/ActiveLink";
import { Svg } from "components/base/svg/Svg";
import { PrematchContext } from "contexts";
import { RouteNames } from "utils/constants";
import AutoHeight from "components/base/auto-height/AutoHeight";
import styles from "./prematch-top-leagues.module.sass";

type Props = {
    onSelect?: () => void;
};

const PrematchTopLeagues: FC<Props> = ({ onSelect }) => {
    const { changeTournament } = useContext(PrematchContext);
    const router = useRouter();
    const { prematchTopLeagues, prematchIsLoading, prematchFeedError } = useContext(FeedContext);
    const selectedTournamentId = useSelectedTopLeagueTournamentId();
    const hasTopLeagues = prematchTopLeagues.length > 0;

    const handleSelectTournament = useCallback(
        (tid: number) => {
            if (tid !== selectedTournamentId) {
                const tournament = prematchTopLeagues.find((t) => t.id === tid);

                if (tournament) {
                    changeTournament({
                        tournamentId: tournament.id,
                        categoryId: tournament.category_id,
                        sportId: tournament.sport_id,
                    });
                }

                onSelect?.();
            }
        },
        [changeTournament, onSelect, selectedTournamentId, prematchTopLeagues],
    );

    return (
        <>
            <div className={styles.title}>
                <Svg
                    src="/common/desktop/base-icons/top-leagues.svg"
                    wrapper="span"
                    className="svg-icon"
                />
                <Translate tid="sidebar_prematch_topLeagues" />
            </div>

            <AutoHeight wrapperClassName={styles.container} duration={300}>
                {hasTopLeagues &&
                    prematchTopLeagues.map((league) => (
                        <ActiveLink
                            key={league.id}
                            href={{
                                pathname: `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/[[...prematchGameRoutes]]`,
                                query: {
                                    ...router.query,
                                    prematchGameRoutes: [
                                        league?.sport_slug,
                                        `${league?.category_id}`,
                                        `${league?.id}`,
                                    ],
                                },
                            }}
                            shallow
                            forceActive={league?.id === selectedTournamentId}
                            as={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${league.sport_slug}/${league.category_id}/${league.id}`}
                            activeClassName={styles.selected}
                            className={styles.sport}
                            onClick={() => handleSelectTournament(league.id)}>
                            <>
                                <Svg
                                    src={`/common/sport-icons/${league.sport_id}.svg`}
                                    wrapper="span"
                                    className="svg-sport-icon"
                                    loader
                                />
                                <span>{league?.name}</span>
                            </>
                        </ActiveLink>
                    ))}
                {prematchIsLoading ? (
                    <Loading wrapperClassName={styles.loading} />
                ) : (
                    <>
                        {prematchFeedError ? (
                            <div className="no-game">{prematchFeedError}</div>
                        ) : (
                            !hasTopLeagues && (
                                <div className="no-game">
                                    <Translate tid="no_games" />
                                </div>
                            )
                        )}
                    </>
                )}
            </AutoHeight>
        </>
    );
};

export default memo(PrematchTopLeagues);
